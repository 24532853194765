const NoDataFound = ({title}) => {
    return (
        <div class="w-full h-full flex items-center justify-center">
            <p class="text-center text-lg">{title}</p>
        </div>
    );
};

export default NoDataFound;



