import GridCard from "./GridCard";

const CardsGrid = ({ cards }) => {
  return (
    // <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
      {cards.map((card) => (
        <GridCard
          key={card._id}
          image={card.imageUrl}
          title={card.name}
          type={{ id: card.type._id, name: card.type.name }}
          description={card.description}
          linkTo={card.linkTo}
        />
      ))}
    </div>
  );
};

export default CardsGrid;
