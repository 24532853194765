import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const NavLinks = ({ links }) => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  return (
    <>
      {links?.map((link) => (
        <div className="menu-item cursor-pointer" key={link.name}>
          <div className="text-left cursor-pointer group">
            <li
              className="py-7 flex justify-between items-center md:pr-0 group regular cursor-pointer"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              <a href={link.url} className="cursor-pointer">
                {link.name}
              </a>
              <span className="text-xl md:hidden inline">
                <ion-icon
                  name={`${heading === link.name ? "chevron-down-outline" : "chevron-down-outline"}`}
                  style={{ fontSize: "24px", color: "black" }} // Ensure visibility
                ></ion-icon>
              </span>
              <span className="text-xl md:mt-1 md:ml-2 md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon
                  name="chevron-down-outline"
                  style={{ fontSize: "16px", color: "black" }} // Ensure visibility
                ></ion-icon>
              </span>
            </li>
            {link?.submenu && (
              <div>
                {/* Dropdown for desktop */}
                <div className="cursor-pointer absolute top-20 left-1/2 transform -translate-x-1/2 hidden group-hover:md:block hover:md:block w-[80%] md:rounded-lg">
                  <div className="cursor-pointer shadow-xl bg-white p-5 grid grid-cols-3 gap-10 md:rounded-lg">
                    {link.sublinks?.map((mysublinks) => (
                      <div key={mysublinks.Head}>
                        <a href={`${mysublinks.headLink}`} className="cursor-pointer">
                          <p className="text-lg font-semibold cursor-pointer">{mysublinks.Head}</p>
                        </a>

                        {mysublinks.sublink?.map((slink) => (
                          <li className="text-sm text-gray-600 my-2.5 cursor-pointer" key={slink.link}>
                            <a href={slink.link} className="hover:text-primary cursor-pointer">
                              {slink.name}
                            </a>
                          </li>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Mobile menus */}
          <div className={`${heading === link.name ? "md:hidden" : "hidden"}`}>
            {/* Sublinks for mobile */}
            {link?.sublinks?.map((slinks) => (
              <div key={slinks.Head}>
                <div>
                  <p
                    onClick={() => (subHeading !== slinks.Head ? setSubHeading(slinks.Head) : setSubHeading(""))}
                    className="py-4 pl-7 md:pr-0 pr-5 flex justify-between items-center cursor-pointer"
                  >
                    <a href={slinks.headLink}>{slinks.Head}</a>
                    <span className="text-xl md:mt-1 md:ml-2 inline">
                      <ion-icon
                        name={`${subHeading === slinks.Head ? "chevron-up-outline" : "chevron-down-outline"}`}
                        style={{ fontSize: "24px", color: "black" }} // Ensure visibility
                      ></ion-icon>
                    </span>
                  </p>

                  <div className={`${subHeading === slinks.Head ? "md:hidden" : "hidden"}`}>
                    {slinks.sublink?.map((slink) => (
                      <li className="py-3 pl-14 cursor-pointer" key={slink.link}>
                        <a href={slink.link} className="cursor-pointer">
                          {slink.name}
                        </a>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
