import React from 'react';
import { Link } from 'react-router-dom';

import "../styles/index.css";
import "../styles/components.css";

const { FooterSections } = require('../enums/FooterSections');

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="container">
                <div className="footer-sections-container">
                    <div className="footer-section">
                        <a href="/"><img src='/logo.svg'/></a>

                        <p className="small">Amett Group, Leaders in Clinical Engineering Services and Medical Equipment Maintenance Solutions
                        </p>
                    </div>

                    {Object.values(FooterSections).map((section) => (
                        <div className="footer-section" key={section.title}>
                            <h5 className="small bold">{section.title}</h5>
                            <ul>
                                {section.links.map((link) => (
                                    <li key={link.url}>
                                        <Link to={link.url}>{link.name}</Link> {/* Use Link from react-router-dom */}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}

                    <div className="footer-section">
                        <h5 className="small bold">Location</h5>
                        <p className="small">
                            2972 Westheimer Rd. Santa Ana, Illinois 85486
                        </p>
                    </div>
                </div>
                <hr/>
                <p className="small">Copyright © 2024 Amett. All rights reserved.</p>
            </div>
        </footer>
    );
};

export default Footer;
