
const LoadingGridCard = () => {
  return (
    <div className="h-[250px] bg-gray-200 border border-gray-300 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300 animate-pulse">
      <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"></div>
    </div>
  );
};

const LoadingCardGrid = () => {
  const items = [
    { id: 1 },
    { id: 2 },
    { id: 3 },
    { id: 4 },
    { id: 5 },
    { id: 6 },
    { id: 7 },
    { id: 8 },
    { id: 9 },
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-4">
      {items.map((item) => (
        <LoadingGridCard key={item.id} />
      ))}
    </div>
  );
};

export default LoadingCardGrid;
