import React from "react";
import "../styles/index.css";
import "../styles/components.css";

import lectureRoomImage from "../assets/aboutUsHero.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";

const WhoWeAreSection = () => {
  return (
    <section className="container page-section-margin">
      <div className="mx-auto text-center">
        <h2 className="text-4xl font-bold mb-4">Who we are</h2>
        <p className="text-lg mb-12 max-w-2xl mx-auto">
          AMETT is a Company that drives patient-centric value-based healthcare through turnkey engineering solutions
          and delivering productivity across labor, operations, and asset utilization.
        </p>

        <div className="flex flex-col lg:flex-row lg:justify-center lg:items-start">
          <div className="lg:w-5/12 mb-8 lg:mb-0 h-96">
            <img
              className="rounded-lg w-full h-full object-cover"
              src={lectureRoomImage}
              alt="Company team in a conference room"
            />
          </div>

          <div className="lg:w-6/12 lg:pl-10 text-left">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 lg:grid-cols-1">
              <div className="border-black border-l-2 mb-2 px-3">
                <h5 className="text-sm font-semibold flex items-center text-green-500">
                  We Aspire <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                </h5>
                <p className="text-sm my-0 text-gray-700">
                  To transform healthcare systems using cutting-edge engineering, digital, and consulting solutions.
                </p>
              </div>
              <div className="border-black border-l-2 mb-2 px-3">
                <h5 className="text-sm font-semibold flex items-center text-green-500">
                  We Optimize <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                </h5>
                <p className="text-sm my-0 text-gray-700">Healthcare operations performance.</p>
              </div>
              <div className="border-black border-l-2 mb-2 px-3">
                <h5 className="text-sm font-semibold flex items-center text-green-500">
                  We Digitize <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                </h5>
                <p className="text-sm my-0 text-gray-700">
                  The patient experience journey allows patients and healthcare professionals to access the right
                  information at the right time to make the right decision, delivering the right impact.
                </p>
              </div>
              <div className="border-black border-l-2 mb-2 px-3">
                <h5 className="text-sm font-semibold flex items-center text-green-500">
                  We Supply <FontAwesomeIcon icon={faCheckCircle} className="ml-2" />
                </h5>
                <p className="text-sm my-0 text-gray-700">
                  We provide the market with the highest tech technologies to integrate the existing systems and attain
                  the 2030 vision.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAreSection;
