import React from 'react';
import {useState} from "react";
import { Link } from 'react-router-dom';
import "../../styles/components.css"


const ServiceCard = ({title, img, linkTo}) => {
    const [style, setStyle] = useState(false);
    return (
        <Link to={linkTo} className="service-card-link">
        <div className="service-card-container">
            <div className="service-card-img" style={{ backgroundImage: `url(${img})` }}
                 onMouseEnter={e => {
                     setStyle(true);
                 }}
                 onMouseLeave={e => {
                     setStyle(false)
                 }}
            >
                {style && <ion-icon name="arrow-back-sharp" class="card-hover-icon"></ion-icon>}

            </div>
            <h3 className="bold">{title}</h3>
        </div>
        </Link>
    );
};

export default ServiceCard;
