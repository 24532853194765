import React, { useEffect, useRef, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import Breadcrumb from "../components/Breadcrumb";
import "../styles/index.css";
import "../styles/components.css";
import CustomButton from "../components/CustomButton";
import RequestCallBackForm from "../components/RequestCallBackForm";
import LoadingText from "../components/LoadingText";

const Product = () => {
  const { id } = useParams(); // Get ID from URL params
  const { state } = useLocation(); // Get the state passed via Link or navigate
  const formRef = useRef(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [product, setProduct] = useState(null);
  const [error, setError] = useState(null); // For error state

  // Fetch product data based on ID or state
  const productData = async () => {
    if (state) {
      // If state is passed, use it directly
      setProduct(state);
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/equipment-models/${id}`); // API endpoint for fetching product data
      if (response.ok) {
        let data = await response.json();
        data.data.equipmentModel.title = data.data.equipmentModel.name;
        setProduct(data.data.equipmentModel);
      } else {
        setError("Failed to load product data.");
      }
    } catch (error) {
      setError("Error fetching data.");
      console.error("Error fetching product data:", error);
    }
  };

  // Scroll to the RequestCallBackForm
  const handleButtonClick = () => {
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  // Run productData on component mount or when ID changes
  useEffect(() => {
    productData();
  }, [id, state]); // Depend on both id and state to trigger fetch when either changes

  return (
    <>
      <>
        <div className="container page-section-margin">
          {product && (
            <Breadcrumb listingType="products" listingCategory={product?.type} listingName={product?.title} />
          )}

          <section className="listing-container">
            <div className="listing-info">
              {product ? (
                <>
                  <h1>{product.title}</h1>
                  <p>{product.description}</p>
                </>
              ) : (
                <>
                  <LoadingText />
                  <LoadingText height="250" />
                </>
              )}

              <CustomButton
                linkTo="#"
                isFilled={true}
                title={"Request Callback"}
                onClick={handleButtonClick} // Scroll to form on click
              />
            </div>
            <div
              className="listing-img"
              style={{ backgroundImage: `url(${product?.imageUrl || product?.image || "/default-img.jpg"})` }} // Fallback image
            ></div>
          </section>
        </div>

        <section className="container page-section-margin">
          <h2 className="bold">Product Information</h2>
          {product ? <p>{product?.information || product?.description}</p> : <LoadingText height="250" />}
        </section>

        <div ref={formRef}>
          <RequestCallBackForm />
        </div>
      </>
    </>
  );
};

export default Product;
