import React, { useState, useEffect } from "react";
import "../styles/index.css";
import "../styles/components.css";
import HeroSection from "../components/Hero/HeroSection";
import ServiceCard from "../components/Sliders/ServiceCard";
import Slider from "../components/Sliders/Slider";
import WhyChooseUs from "../components/WhyChooseUs";
import RequestCallBackForm from "../components/RequestCallBackForm";
import Solutions from "../components/Solutions";
import aboutHeroImage from "../assets/aboutHero.png";
import defaultImage from "../assets/defaultImage.png";
import medicalEquipmentImage from "../assets/mEquipHero.png";
import { fetchAndSaveToLocalStorage } from "../utils/fetchAndSaveToLocalStorage";

const About = () => {
 
  const [featuredSliderData, setFeaturedSliderData] = useState([]);

  const getFeaturedSliderData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/web/featured-service?limit=9`;
    const data = await fetchAndSaveToLocalStorage(apiUrl, "featured-service-data");
    if (data) {
      setFeaturedSliderData(data.data.services);
    } else {
      console.error("Failed to fetch data");
    }
  };
  useEffect(() => {
    getFeaturedSliderData();
  }, []);
  return (
    <>
      <HeroSection
        title="Welcome to AMETT Group"
        desc="Leaders in Clinical Engineering Services and Medical Equipment Maintenance Solutions"
        img={aboutHeroImage}
        buttonConfigs={[]}
      />

      <WhyChooseUs />

      <div id="our-solution">
      <Slider
        background={true}
        title={"Clinical Engineering Services"}
        desc={
          "Our clinical engineering team is dedicated to bridging the gap between advanced medical technology and practical healthcare solutions. We offer a range of services tailored to meet the unique needs of healthcare facilities:"
        }
        buttonTitle={"All Services"}
        buttonLinkTo={"/services"}
      >
        {featuredSliderData.map((service) => (
          <ServiceCard key={service.id} title={service.name} img={service.imageUrl} linkTo={service.linkTo} />
        ))}
      </Slider>
      </div>

      {/* Commented out due to redundancy for now, can be brought back by creating a 'Solution' category & showing them here. */}
      {/* <Solutions title="Medical Equipment Maintenance Solutions" /> */}

      <HeroSection
        title="Medical Equipment Store"
        desc=" Your trusted partner for reliable, high-quality medical devices and equipment. We provide solutions that ensure safety, accuracy, and innovation for healthcare providers."
        img={medicalEquipmentImage}
        buttonConfigs={[
          { linkTo: "/medicalEquipment", title: "Explore Products", isFilled: true },
          { linkTo: "#contact-us-form", title: "Contact Us", isFilled: false },
        ]}
      />

      <RequestCallBackForm />
    </>
  );
};

export default About;
