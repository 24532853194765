// Home.js
import React from 'react';

const ContactUs = () => {
  return (
    <div>
      <h1>Welcome to the Contact Page!</h1>
      <p>This is the main page of our application. Explore our features and enjoy your stay!</p>
    </div>
  );
};

export default ContactUs;
