
const LoadingGridCard = () => {
    return (
      <div className="h-[250px] w-[450px] bg-gray-200 border border-gray-300 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300 animate-pulse">
        <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"></div>
      </div>
    );
  };
  
  const LoadingSlider = () => {
    const items = [
      { id: 1 },
      { id: 2 },
      { id: 3 },
      
    ];
  
    return (
        <>
        {items.map((item) => (
          <LoadingGridCard key={item.id} />
        ))}
        </>
    );
  };
  
  export default LoadingSlider;
  