import { Link } from 'react-router-dom';

const GridCard = ({ image, title, description, type, linkTo }) => {
  const dataToPass = { image, title, description , type}; // Package all props into an object
  return (
    <>
      <Link to={linkTo} state={dataToPass}>
        <div className="bg-gray-50 border border-gray-200 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300">
          <img src={image} alt={title} className="w-full h-40 object-cover" />
          <div className="p-4">
            <h3 className="text-md font-semibold mb-2 text-gray-800">{title}</h3>
            <p className="text-sm text-gray-600 mb-4">{description}</p>
            {/* <button className="text-blue-600 hover:text-blue-800 text-sm font-medium">Explore Service →</button> */}
          </div>
        </div>
      </Link>
    </>
  );
};

export default GridCard;
