import React from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import MedicalEqu from "./pages/MedicalEqu";
import ClinicalServices from "./pages/ClinicalServices";
import ContactUs from "./pages/ContactUs";
import Product from "./pages/Product";
import Service from "./pages/Service";
import ServiceCategories from "./pages/ServiceCategories";
import ProductCategories from "./pages/ProductCategories";
import Navbar from "./components/Navbar/Navbar";
import ScrollToTopOrHash from "./components/ScrollToTopOrHash";

const App = () => {
  return (
    <>
      <ScrollToTopOrHash />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/clinicalServices" element={<ClinicalServices />} />
        <Route path="/medicalEquipment" element={<MedicalEqu />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/service/:id" element={<Service />} />
        <Route path="/services" element={<ServiceCategories />} />
        <Route path="/products" element={<ProductCategories />} />
      </Routes>
      <Footer />
    </>
  );
};

export default App;
