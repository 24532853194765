import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTopOrHash = () => {
  const { pathname, hash } = useLocation();

  useLayoutEffect(() => {
    // Wait until the next frame to ensure all elements are available
    const scrollToHash = () => {
      if (hash) {
        const element = document.getElementById(hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    };

    // Use requestAnimationFrame to wait until the next render cycle
    requestAnimationFrame(scrollToHash);
  }, [pathname, hash]);

  return null;
};

export default ScrollToTopOrHash;
