import React, { useState, useEffect } from "react";
import "../styles/index.css";
import "../styles/components.css";
import HeroSection from "../components/Hero/HeroSection";
import ServiceCard from "../components/Sliders/ServiceCard";
import Slider from "../components/Sliders/Slider";
import WhoWeAreSection from "../components/WhoWeAreSection";
import RequestCallBackForm from "../components/RequestCallBackForm";
import WhyChooseUs from "../components/WhyChooseUs";
import Solutions from "../components/Solutions";
import { fetchAndSaveToLocalStorage } from "../utils/fetchAndSaveToLocalStorage";
import LoadingSlider from "../components/LoadingSlider";
import medicalEquipmentImage from "../assets/mEquipHero.png";

const MedicalEqu = () => {
  const [featuredSliderData, setFeaturedSliderData] = useState(null);

  const getFeaturedSliderData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/web/featured-equipment`;
    const data = await fetchAndSaveToLocalStorage(apiUrl, "featured-equipment-data");
    if (data) {
      setFeaturedSliderData(data.data.equipmentModels);
    } else {
      console.error("Failed to fetch data");
    }
  };

  useEffect(() => {
    getFeaturedSliderData();
  }, []);

  return (
    <>
      <HeroSection
        highlightTitle="Medical Equipment"
        title="Your Trusted Medical Store"
        desc=" Your trusted partner for reliable, high-quality medical devices and equipment. We provide solutions that ensure safety, accuracy, and innovation for healthcare providers."
        img={medicalEquipmentImage}
        buttonConfigs={[{ linkTo: "/products", title: "Explore Equipment", isFilled: false }]}
      />

      <Slider
        background={false}
        highlightTitle={"Featured"}
        title={"Medical Equipment"}
        desc={
          "Explore our featured medical equipment designed to meet the highest standards of quality and reliability."
        }
        buttonTitle={"All Products"}
        buttonLinkTo={"/products"}
      >
        {featuredSliderData ? (
          featuredSliderData?.map((data) => (
            <ServiceCard key={data.id} title={data.name} img={data.imageUrl} linkTo={data.linkTo} />
          ))
        ) : (
          <LoadingSlider />
        )}
      </Slider>

      <RequestCallBackForm />
    </>
  );
};

export default MedicalEqu;
