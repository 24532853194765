import React, { useState } from "react";
import validator from "validator";

import teamMember1 from "../assets/support1.png";
import teamMember2 from "../assets/support2.png";
import teamMember3 from "../assets/support3.png";

const RequestCallBackForm = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
  });

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false); // State to track loading

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);
    setLoading(true); // Start loading

    const { name, email, phone, date } = formData;

    // Basic validation
    if (!name || !email || !phone || !date) {
      setError("All fields are required.");
      setLoading(false); // Stop loading
      return;
    }

    if (!validator.isEmail(email)) {
      setError("Please enter a valid email address.");
      setLoading(false); // Stop loading
      return;
    }
    
    if (!validator.isMobilePhone(phone, "any")) {
      setError("Please enter a valid phone number in international format (e.g., +11234567890).");
      setLoading(false); // Stop loading
      return;
    }

    const selectedDate = new Date(date);
    if (selectedDate < new Date()) {
      setError("The date must be in the future.");
      setLoading(false); // Stop loading
      return;
    }

    try {
      const response = await fetch(`${apiUrl}/requests`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        const data = await response.json();
        setSuccess("Your request has been submitted successfully!");
        setFormData({ name: "", email: "", phone: "", date: "" });
      } else {
        const errorData = await response.json();
        setError(errorData.message || "Failed to submit your request.");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div id="contact-us-form" className="container page-section-margin">
      <section
        className="bg-[#011E36] py-10 px-4 md:px-8 lg:px-16 rounded-3xl"
        style={{ padding: " var(--padding-xxlarge) var(--padding-xlarge)" }}
      >
        <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center lg:items-start mx-auto max-w-5xl">
          <div className="text-white w-full lg:w-1/2 mb-8 lg:mb-0">
            <h3 className="text-sm font-medium mb-2 tracking-wide text-left">Need More Information?</h3>
            <h2 className="text-3xl font-semibold mb-4 leading-tight text-left">
              Request a <br /> Call Back
            </h2>
            <p className="text-s mb-8 leading-relaxed text-center sm:text-left hidden md:block sm:pr-64 lg:pr-0">
            We’re here to help! Share your details, and our team will reach out to you promptly.
            </p>
            <div className="md:flex hidden lg:justify-start items-center">
              <img
                className="w-10 h-10 lg:w-12 lg:h-12 rounded-full -mr-3 lg:-mr-4"
                src={teamMember1}
                alt="Team member 1"
              />
              <img
                className="w-10 h-10 lg:w-12 lg:h-12 rounded-full -mr-3 lg:-mr-4"
                src={teamMember2}
                alt="Team member 2"
              />
              <img
                className="w-10 h-10 lg:w-12 lg:h-12 rounded-full"
                src={teamMember3}
                alt="Team member 3"
              />
              <p className="w-36 lg:w-36 mb-0 ml-4 text-xs lg:text-sm lg:text-left">
                Our Team are waiting for your service.
              </p>
            </div>
          </div>

          <div className="w-full lg:w-1/2 rounded-lg shadow-lg lg:ml-16">
            {success && <p className="bg-white text-green-500 mt-4 p-4 rounded-md shadow-md">{success}</p>}
            {error && <p className="bg-white text-red-500 mt-4 p-4 rounded-md shadow-md">{error}</p>}

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="w-full mb-4 p-3 lg:p-4 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2B4BA2]"
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Your Email"
                className="w-full mb-4 p-3 lg:p-4 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2B4BA2]"
                required
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone Number Ex:+11234567890"
                className="w-full mb-4 p-3 lg:p-4 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2B4BA2]"
                required
              />
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleChange}
                className="appearance-none w-full mb-4 p-3 lg:p-4 border border-gray-300 rounded-md placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-[#2B4BA2]"
                required
              />
              <button
                type="submit"
                className="w-full bg-[#28a745] text-white p-3 lg:p-4 rounded-md font-semibold text-base lg:text-lg hover:bg-[#218838] transition duration-200 flex justify-center items-center"
                disabled={loading} // Disable button during loading
              >
                {loading ? (
                  <span className="loader w-5 h-5 border-2 border-t-transparent border-white rounded-full animate-spin"></span>
                ) : (
                  "Request Now"
                )}
              </button>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};

export default RequestCallBackForm;
