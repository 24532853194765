// Breadcrumb.js
import React from 'react';
import { Link } from 'react-router-dom';
import "../styles/components.css"; // Add any specific styling for the breadcrumb here

const Breadcrumb = ({ listingType, listingCategory, listingName }) => {
    return (
        <div className="breadcrumb">
            <Link to={`/${listingType}`} className="breadcrumb-link">{listingType === "products" ? "Medical Equipment" : "Clinical Services"}</Link>
            <span> > </span>
            {listingCategory && (
                <span>
                    <Link to={`/${listingType}?categoryId=${listingCategory._id}`} className="breadcrumb-link">{listingCategory.name}</Link>
                </span>

            )}
            <span> > </span>
            <span>{listingName}</span>
        </div>
    );
};

export default Breadcrumb;
