exports.FooterSections = {
    ABOUT: {
      title: "About Us",
      links: [
        { name: "Why Choose Us", url: "/about#why-choose-us" },
        { name: "Our Solutions", url: "/about#our-solution" },
        { name: "Contact Us", url: "/about#contact-us-form" }
      ]
    },
    SERVICES: {
      title: "Explore",
      links: [
        { name: "Clinical Engineering Services", url: "/clinicalServices" },
        { name: "Medical Devices sales and Distribution", url: "/medicalEquipment" },
      ]
    },

  }
  