export const links = [
  {
    name: "Clinical Engineering Services",
    url: "/clinicalServices",
    submenu: true,
    // sublinks
  },
  {
    name: "Medical Devices sales and Distribution",
    url: "/medicalEquipment",
    submenu: true,
   // sublinks
  }
];
