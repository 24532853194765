import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import NavLinks from "./NavLinks";

import "../../styles/index.css";
import "../../styles/components.css";
import CustomButton from "../CustomButton";
import { links } from "../../enums/NavSections";
import { fetchAndSaveToLocalStorage } from "../../utils/fetchAndSaveToLocalStorage";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const [menuData, setMenuData] = useState(null);

  const getMenuData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/web/menu`;
    const menuData = await fetchAndSaveToLocalStorage(apiUrl, "menu-data");
    if (menuData) {
      links[0].sublinks = menuData.data.services;
      links[1].sublinks = menuData.data.equipment;
      // console.log("menuData:" ,links);
      setMenuData(links);
    } else {
      console.error("Failed to fetch menu data");
    }
  };

  useEffect(() => {
    getMenuData();
  }, []);

  return (
    <nav>
      <div className="flex items-center font-medium container nav-container-fix">
        <div className="z-50 pt-5 pb-5 md:w-auto w-full flex justify-between bg-white">
          <a href={"/"}>
            <img src={"/logo.svg"} alt="logo" className="md:cursor-pointer h-9" />
          </a>
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close-outline" : "menu-outline"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden items-center gap-8 ">
          <li className="cursor-pointer">
            <a href="/about" className="py-7 inline-block">
              About
            </a>
          </li>

          <NavLinks links={menuData} />

          <li className="cursor-pointer">
            <a href="/about#contact-us-form" className="py-7 inline-block">
              Contact Us
            </a>
          </li>
        </ul>
        <div className="md:block hidden">{/* <CustomButton /> */}</div>
        {/* Mobile nav */}
        <ul
          className={`md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 duration-500 nav-container-mobile-fix ${
            open ? "left-0" : "left-[-100%]"
          }`}
        >
          <li>
            <a href="/about" className="py-7 inline-block">
              About
            </a>
          </li>
          <NavLinks links={links} />
          <li>
            <a href="/about#contact-us-form" className="py-7 inline-block">
              Contact Us
            </a>
          </li>
          <div className="py-5">{/* <CustomButton /> */}</div>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
