import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CategorySidebar from "../components/CategorySidebar";
import CardsGrid from "../components/CardsGrid";
import "../styles/index.css";
import "../styles/components.css";
import { fetchAndSaveToLocalStorage } from "../utils/fetchAndSaveToLocalStorage";
import LoadingCardGrid from "../components/LoadingCardGrid";
import NoDataFound from "../components/NoDataFound";

const ServiceCategory = () => {
  const [categoriesData, setCategoriesData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [services, setServices] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchServices = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/services/category/${selectedCategory}`;
    const servicesData = await fetchAndSaveToLocalStorage(apiUrl, `service-cat-${selectedCategory}`);
    if (servicesData) {
      setServices(servicesData.data.services);
    } else {
      console.error("Failed to fetch categories");
    }
  };

  // Fetch categories from the API and save to local storage
  const fetchCategories = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/service-types`;
    const categoriesData = await fetchAndSaveToLocalStorage(apiUrl, "service-types");
    if (categoriesData) {
      setCategoriesData(categoriesData.data.serviceTypes);
    } else {
      console.error("Failed to fetch categories");
    }
  };

  useEffect(() => {
    fetchServices();
  }, [selectedCategory]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // Ensure categoriesData exists and has items before proceeding
    if (categoriesData && categoriesData.length > 0) {
      const categoryIdFromUrl = new URLSearchParams(location.search).get("categoryId");
      // Navigate only if categoryIdFromUrl is missing or invalid
      if (!categoryIdFromUrl || !categoriesData.find((category) => category._id === categoryIdFromUrl)) {
        navigate(`/services?categoryId=${categoriesData[0]._id}`, { replace: true });
        setSelectedCategory(categoriesData[0]._id); // Default to the first category
      } else {
        setSelectedCategory(categoryIdFromUrl); // Set category from URL
      }
    }
  }, [categoriesData, location, navigate]);

  // Handle category selection
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId); // Update selected category state
    navigate(`/services?categoryId=${categoryId}`); // Update the URL with the selected category ID
  };

  return (
    <div className="max-w-6xl mx-auto flex flex-col md:flex-row min-h-screen">
      {/* Sidebar */}
      <CategorySidebar
        categories={categoriesData}
        title={"Clinical Engineering Services"}
        selectedCategory={selectedCategory}
        onCategoryClick={handleCategoryClick}
        className="w-full md:w-1/4 bg-gray-100 p-4"
      />

      {/* Content */}
      <div className="flex-1 p-4">
        {services ? (
          services.length > 0 ? (
            <CardsGrid cards={services} />
          ) : (
            <NoDataFound title={"No services found"} />
          )
        ) : (
          <LoadingCardGrid />
        )}
      </div>
    </div>
  );
};

export default ServiceCategory;
