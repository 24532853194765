import React from 'react';
import PropTypes from 'prop-types';
import "../../styles/index.css";
import "../../styles/components.css";
import CustomButton from "../CustomButton";

const HeroSection = ({
                         highlightTitle = '',
                         title = '',
                         desc = '',
                         img,
                         buttonConfigs = [], // Default to an empty array
                     }) => {
    return (
        <div className="container page-section-margin">
            <section className="hero-container">
                <div className="hero-section">
                    {highlightTitle && (
                        <h4 className="bold m-0" style={{ color: 'var(--green)' }}>
                            {highlightTitle}
                        </h4>
                    )}
                    <h1 className="hero bold">{title}</h1>
                    <p>{desc}</p>

                    {buttonConfigs.length > 0 && (
                        <div className="hero-button-container">
                            {buttonConfigs.map((button, index) => (
                                <CustomButton
                                    key={index}
                                    linkTo={button.linkTo}
                                    isFilled={button.isFilled}
                                    title={button.title}
                                />
                            ))}
                        </div>
                    )}
                </div>

                {img ? (
                    <div className="hero-section-img" style={{ backgroundImage: `url(${img})` }}></div>
                ) : (
                    <div className="hero-section-logo">
                        <img className="home-hero-logo" src={"/logo.svg"} alt="logo" />
                    </div>
                )}
            </section>
        </div>
    );
};

HeroSection.propTypes = {
    highlightTitle: PropTypes.string,
    title: PropTypes.string.isRequired,
    desc: PropTypes.string.isRequired,
    img: PropTypes.string,
    buttonConfigs: PropTypes.arrayOf(
        PropTypes.shape({
            linkTo: PropTypes.string.isRequired,
            title: PropTypes.string.isRequired,
            isFilled: PropTypes.bool,
        })
    ),
};

export default HeroSection;
