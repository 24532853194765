import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import CategorySidebar from "../components/CategorySidebar";
import CardsGrid from "../components/CardsGrid";
import "../styles/index.css";
import "../styles/components.css";
import { fetchAndSaveToLocalStorage } from "../utils/fetchAndSaveToLocalStorage";
import LoadingCardGrid from "../components/LoadingCardGrid";
import NoDataFound from "../components/NoDataFound";

const ProductCategory = () => {
  const [categoriesData, setCategoriesData] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const fetchProducts = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/equipment-models/category/${selectedCategory}`;
    const productsData = await fetchAndSaveToLocalStorage(apiUrl, `product-cat-${selectedCategory}`);
    if (productsData) {
      setProducts(productsData.data.equipmentModels);
    } else {
      console.error("Failed to fetch categories");
    }
  };

  // Fetch categories from the API and save to local storage
  const fetchCategories = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/equipment-types`;
    const categoriesData = await fetchAndSaveToLocalStorage(apiUrl, "equipment-types");
    if (categoriesData) {
      setCategoriesData(categoriesData.data.equipmentTypes);
    } else {
      console.error("Failed to fetch categories");
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [selectedCategory]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    // Ensure categoriesData exists and has items before proceeding
    if (categoriesData && categoriesData.length > 0) {
      const categoryIdFromUrl = new URLSearchParams(location.search).get("categoryId");
      // Navigate only if categoryIdFromUrl is missing or invalid
      if (!categoryIdFromUrl || !categoriesData.find((category) => category._id === categoryIdFromUrl)) {
        navigate(`/products?categoryId=${categoriesData[0]._id}`, { replace: true });
        setSelectedCategory(categoriesData[0]._id); // Default to the first category
      } else {
        setSelectedCategory(categoryIdFromUrl); // Set category from URL
      }
    }
  }, [categoriesData, location, navigate]);

  // Handle category selection
  const handleCategoryClick = (categoryId) => {
    setSelectedCategory(categoryId); // Update selected category state
    navigate(`/products?categoryId=${categoryId}`); // Update the URL with the selected category ID
  };

  return (
    <div className="max-w-6xl mx-auto flex flex-col md:flex-row min-h-screen">
      <CategorySidebar
        categories={categoriesData}
        title={"Medical Equipment"}
        selectedCategory={selectedCategory}
        onCategoryClick={handleCategoryClick}
      />
      <div className="flex-1 p-4">
        {products ? (
          products.length > 0 ? (
            <CardsGrid cards={products} />
          ) : (
            <NoDataFound title={"No products found"} />
          )
        ) : (
          <LoadingCardGrid />
        )}
      </div>
    </div>
  );
};

export default ProductCategory;
