import React, { useState, useEffect } from "react";
import "../styles/index.css";
import "../styles/components.css";
import HeroSection from "../components/Hero/HeroSection";
import RequestCallBackForm from "../components/RequestCallBackForm";
import Solutions from "../components/Solutions";
import clinicalServicesImage from "../assets/cEngineeringHero.png";
import medicalEquipmentImage from "../assets/mEquipHero.png";
import { fetchAndSaveToLocalStorage } from "../utils/fetchAndSaveToLocalStorage";

const ClinicalServices = () => {
  const [featuredSliderData, setFeaturedSliderData] = useState([]);

  const getFeaturedSliderData = async () => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/web/featured-service?limit=4`;
    const data = await fetchAndSaveToLocalStorage(apiUrl, "featured-service-data");
    if (data) {
      setFeaturedSliderData(data.data.services);
    } else {
      console.error("Failed to fetch data");
    }
  };
  useEffect(() => {
    getFeaturedSliderData();
  }, []);

  return (
    <>
      <HeroSection
        title={"Clinical Engineering Services"}
        desc={
          "We provide the expertise and support needed to empower your clinical engineering teams, allowing them to operate efficiently and strategically. "
        }
        img={clinicalServicesImage}
        buttonConfigs={[{ linkTo: "/services", title: "All Services", isFilled: false }]}
      />

      <Solutions title="Our Services" services={featuredSliderData} />

      <HeroSection
        highlightTitle="Explore"
        title="Medical Equipment Store"
        desc=" Your trusted partner for reliable, high-quality medical devices and equipment. We provide solutions that ensure safety, accuracy, and innovation for healthcare providers."
        img={medicalEquipmentImage}
        buttonConfigs={[{ linkTo: "/medicalEquipment", title: "Shop Now", isFilled: true }]}
      />

      <RequestCallBackForm />
    </>
  );
};

export default ClinicalServices;
