import React from "react";
import "../styles/index.css";
import "../styles/components.css";
import HeroSection from "../components/Hero/HeroSection";
import WhoWeAreSection from "../components/WhoWeAreSection";
import RequestCallBackForm from "../components/RequestCallBackForm";

const Home = () => {
  return (
    <>
      <HeroSection
        title={"Unlock the promise of Medical Technology "}
        desc={"to transform the Future of Patient Care"}
        buttonConfigs={[
          { linkTo: "/medicalEquipment", title: "Explore Products", isFilled: true },
          { linkTo: "/about", title: "About Us", isFilled: false },
        ]}
      />
      <WhoWeAreSection />
      <RequestCallBackForm />
    </>
  );
};

export default Home;
