import React from "react";
import { Link } from "react-router-dom";
import "../styles/components.css";

const CustomButton = ({
  isFilled = true, // Default style type
  title = "Click Me",
  linkTo = "/",
  className,
  onClick, // Accept an onClick prop
}) => {
  return (
    <Link to={linkTo}>
      <div className={`button ${isFilled ? "" : "button-outline"} ${className}`} onClick={onClick}>
        {title}
      </div>
    </Link>
  );
};

export default CustomButton;
