import React, { useEffect, useRef, useState } from "react";
import "../../styles/components.css";
import "../../styles/index.css";
import CustomButton from "../CustomButton";

const Slider = ({ background = true, highlightTitle, title, desc, buttonTitle, buttonLinkTo, children }) => {
  const containerRef = useRef(null); // Reference to the container
  const [marginLeft, setMarginLeft] = useState(0); // State to hold the margin-left value

  useEffect(() => {
    // Function to get margin of the container class
    const updateMargin = () => {
      if (containerRef.current) {
        const computedStyle = getComputedStyle(containerRef.current); // Get computed styles of the container
        const marginLeftValue = parseInt(computedStyle.marginLeft, 10) + parseInt(computedStyle.paddingLeft, 10); // Parse and sum margin-left and padding-left
        setMarginLeft(marginLeftValue); // Set the margin-left value for slider-container
      }
    };

    updateMargin(); // Call the function on mount
    window.addEventListener("resize", updateMargin); // Update on window resize

    return () => {
      window.removeEventListener("resize", updateMargin); // Cleanup the event listener
    };
  }, []);

  return (
    <section className={`${background ? "slider-container" : "slider-container-nobackground"} page-section-margin`}>
      <div className="container" ref={containerRef}>
        <div className="slider-text-container">
          {highlightTitle && (
            <h4 className="bold m-0" style={{ color: "var(--blue)" }}>
              {highlightTitle}
            </h4>
          )}
          <h1 className="bold">{title}</h1>
          <p>{desc}</p>
          <CustomButton title={buttonTitle} linkTo={buttonLinkTo} isFilled={false} className={"slider-button"} />
        </div>
      </div>

      <div className="slide-container" style={{ padding: `0px ${marginLeft}px` }}>
        {React.Children.map(children, (child) => child)}
      </div>
    </section>
  );
};

export default Slider;
