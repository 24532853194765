import React from "react";
import serviceImage1 from "../assets/c8fb21543dfbb4dec7bf8e7da28078c8.jpg";
import serviceImage2 from "../assets/c4f99175d69d417d4cfec65943752c75.jpg";
import serviceImage3 from "../assets/f97653d4f519b10d1eda73c2614545b5.jpg";
import serviceImage4 from "../assets/f95abf4da65e34f920e564b0b8d5eba9.jpg";
const Solutions = ({ services, title }) => {
  // const services = [
  //   {
  //     title: "Preventive Maintenance",
  //     description: "Our proactive approach helps ensure regular inspections and maintenance of your equipment.",
  //     image: serviceImage1, // Replace with actual image link
  //     link: "/service/1",
  //   },
  //   {
  //     title: "Corrective Maintenance",
  //     description:
  //       "In the event of a malfunction, our rapid response teams are ready to diagnose and repair issues efficiently, ensuring downtime is minimized.",
  //     image: serviceImage2, // Replace with actual image link
  //     link: "/service/1",
  //   },
  //   {
  //     title: "Calibration and Testing",
  //     description:
  //       "Our precise calibration services and testing solutions help ensure optimal equipment accuracy and adhere to necessary performance standards.",
  //     image: serviceImage3, // Replace with actual image link
  //     link: "/service/1",
  //   },
  //   {
  //     title: "Equipment Upgrades",
  //     description:
  //       "Stay ahead with equipment advancements as our experts help upgrade or replace aging medical equipment, enabling you to continue delivering healthcare excellence.",
  //     image: serviceImage4, // Replace with actual image link
  //     link: "/service/1",
  //   },
  // ];

  return (
    <div id="our-solution" className="container page-section-margin">
      <div className="max-w-7xl mx-auto">
        <h2 className="max-w-md text-3xl font-extrabold text-gray-900 mb-6">{title}</h2>
        <p className="max-w-md text-md text-gray-700 mb-10">
          Ensuring the reliability and longevity of medical equipment is essential for uninterrupted healthcare
          delivery.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div key={index} className="bg-gray-100 shadow-md rounded-2xl overflow-hidden">
              <img src={service.imageUrl} alt={service.name} className="h-72 w-full object-cover" />
              <div className="p-6">
                <h3 className="text-xl font-semibold mb-3">{service.name}</h3>
                <p className="text-gray-600 mb-5">{service.description}</p>
                <a href={service.linkTo} className="hover:underline font-medium">
                  Explore Service &rarr;
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Solutions;
