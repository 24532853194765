import React from "react";

const CategorySidebar = ({ categories, title, selectedCategory, onCategoryClick }) => {
  return (
    <div className="w-full md:w-64 bg-gray-100 p-4 rounded-md shadow-md mb-4">
      <h2 className="text-2xl font-bold mb-6">{title}</h2>
      <ul className="lg:space-y-4 md:space-y-4 category-cards-container">
        {categories?.map((category) => (
          <li key={category._id} className="category-card-container">
            <button
              onClick={() => onCategoryClick(category._id)} // Trigger onClick handler passed from parent
              className={`flex justify-between items-center w-full text-left p-2 rounded-md 
                ${selectedCategory === category._id ? "bg-gray-200" : "bg-white"} 
                hover:bg-gray-200`} // Apply bg-gray-200 when selected
            >
              <span>{category.name}</span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategorySidebar;
