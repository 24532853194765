
const LoadingText = ({height = 30}) => {
    return (
        <div className={`z-0 mb-4 h-[${height}px] bg-gray-200 rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-shadow duration-300 animate-pulse`}>
        <div className="h-full w-full bg-gradient-to-r from-gray-200 via-gray-300 to-gray-200"></div>
      </div>
    );
  };
  
  export default LoadingText;
  