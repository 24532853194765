export const fetchAndSaveToLocalStorage = async (apiUrl, cacheKey, cacheExpiryTime = 3600000) => {
  // Check if cached data exists and is not expired
  const cachedData = localStorage.getItem(cacheKey);
  const cachedTime = localStorage.getItem(`${cacheKey}Timestamp`);
  const currentTime = Date.now();

  if (cachedData && cachedTime && currentTime - cachedTime < cacheExpiryTime) {
    return JSON.parse(cachedData); // Return the cached data
  }

  // If no cached data or it has expired, fetch from API
  try {
    const response = await fetch(apiUrl); // Replace with your API endpoint
    const data = await response.json();

    if (response.ok) {
      // Save the fetched data to localStorage
      localStorage.setItem(cacheKey, JSON.stringify(data));
      localStorage.setItem(`${cacheKey}Timestamp`, currentTime.toString()); // Save timestamp
      return data; // Return the fetched data
    } else {
      throw new Error("Failed to fetch data");
    }
  } catch (error) {
    console.error(`Error fetching data for ${cacheKey}:`, error);
    return null; // Return null if the API call fails
  }
};
